@import "../../node_modules/@storefront-ui/shared/styles/helpers/breakpoints";
@import "../../node_modules/@storefront-ui/shared/styles/helpers/typography";
@import "../../node_modules/@storefront-ui/shared/styles/helpers/layout";
@import "../../node_modules/@storefront-ui/shared/styles/helpers/colors";
@import "../../node_modules/@storefront-ui/shared/styles/helpers/sizes";




















































.svg-image {
  align-items: center;
  display: inline-flex;

  svg {
    fill: currentColor;
  }
}
