@import "../../node_modules/@storefront-ui/shared/styles/helpers/breakpoints";
@import "../../node_modules/@storefront-ui/shared/styles/helpers/typography";
@import "../../node_modules/@storefront-ui/shared/styles/helpers/layout";
@import "../../node_modules/@storefront-ui/shared/styles/helpers/colors";
@import "../../node_modules/@storefront-ui/shared/styles/helpers/sizes";





























.topbar {
  position: relative;
  z-index: 2;
  background: #000;
  color: #fff;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 12px;
  font-weight: 300;
  &__button {
    margin: 0 0 0 var(--spacer-xs);
  }
}

::v-deep {
  .sf-top-bar__container {
    justify-content: space-between;
    & > * {
      width: calc(100% / 3);
      justify-content: center;
    }
    & > :first-child {
      justify-content: flex-start;
    }
    & > :last-child {
      justify-content: flex-end;
    }
  }
}
