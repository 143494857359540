@import "../node_modules/@storefront-ui/shared/styles/helpers/breakpoints";
@import "../node_modules/@storefront-ui/shared/styles/helpers/typography";
@import "../node_modules/@storefront-ui/shared/styles/helpers/layout";
@import "../node_modules/@storefront-ui/shared/styles/helpers/colors";
@import "../node_modules/@storefront-ui/shared/styles/helpers/sizes";

































































































































































































































































.sf-header {
  --header-padding: var(--spacer-sm);
  @include for-desktop {
    --header-padding: 0 var(--spacer-sm);
  }

  &__switchers {
    display: flex;
  }
}

.header-on-top {
  z-index: 2;
}

.cart-badge {
  position: absolute;
  bottom: 40%;
  left: 40%;
}
.navbar {
  background-color: #f8f5f0;
  padding: 0;
  min-height: auto;
  border: 0;
  @include for-mobile {
    display: none;
  }
}
.navbar-collapse.collapse {
  display: block !important;
  padding-bottom: 0;
  overflow: visible !important;
}
.category-menu {
  display: flex;
  .sub-category-menu1 {
    width: 90%;
  }
  .sub-category-menu2 {
    width: 10%;
    ::v-deep .header-navigation {
      &__subcategories {
        left: 80%;
        width: 18%;
        display: block;
        padding: 10px;
      }
      &__subcategory {
        border-left: none;
      }
    }
  }
}

::v-deep .sf-button--pure {
  display: flex;
  flex-direction: column;
  span {
    padding: 5px;
  }
  .sub-title {
    color: var(--c-primary);
    font-weight: 400;
    font-size: var(--font-size--xs);
  }
}
.sf-input {
  background: #f3f2f0;
  border-radius: 10px;
  border-style: groove;
  border-width: 1px;
}
