@import "../node_modules/@storefront-ui/shared/styles/helpers/breakpoints";
@import "../node_modules/@storefront-ui/shared/styles/helpers/typography";
@import "../node_modules/@storefront-ui/shared/styles/helpers/layout";
@import "../node_modules/@storefront-ui/shared/styles/helpers/colors";
@import "../node_modules/@storefront-ui/shared/styles/helpers/sizes";

































































































@import "~@storefront-ui/vue/styles";
@import "~/assets/main";
#layout {
  box-sizing: border-box;
  @include for-desktop {
    max-width: 1440px;
    margin: auto;
    padding: 10px;
  }
}

.no-scroll {
  overflow: hidden;
  height: 100vh;
}

// Reset CSS
html {
  @include generate-color-variants(--_c-green-primary, #111214);
  width: auto;
  @include for-mobile {
    overflow-x: hidden;
  }
  --font-family--primary: Lato, mjsIcons, sans-serif !important;
  --font-family--second: "Open Sans", "Helvetica Neue", Helvetica, Arial,
    sans-serif !important;
}

body {
  overflow-x: hidden;
  color: var(--c-text);
  font-size: var(--font-size--base);
  font-family: var(--font-family--primary);
  background: #f0f0f0;
  margin: 0;
  padding: 0;
}

.page-container {
  box-shadow: 0px 5px 6px #999;
  background: #fff;
  padding: 0x;
  max-width: 1440px;
  margin: 0 auto;
}

a {
  text-decoration: none;
  color: var(--c-link);

  &:hover {
    color: var(--c-link-hover);
  }
}

h1 {
  font-family: var(--font-family--secondary);
  font-size: var(--h1-font-size);
  line-height: 1.6;
  margin: 0;
}

h2 {
  font-family: var(--font-family--secondary);
  font-size: var(--h2-font-size);
  line-height: 1.6;
  margin: 0;
}

h3 {
  font-family: var(--font-family--secondary);
  font-size: var(--h3-font-size);
  line-height: 1.6;
  margin: 0;
}

h4 {
  font-family: var(--font-family--secondary);
  font-size: var(--h4-font-size);
  line-height: 1.6;
  margin: 0;
}
h5 {
  font-family: var(--font-family--secondary);
  font-size: 1.5rem var(--h5-font-size) !important;
  line-height: 1.6;
  margin: 0;
}
.sf-select__dropdown {
  height: 2rem !important;
}
#search {
  margin-top: 3px !important;
}
#search {
  border: none;
  box-shadow: none;
  font-size: 15px;
  height: 30px;
  width: 98%;
  padding-left: 5px;
}
