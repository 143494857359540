@import "../../../node_modules/@storefront-ui/shared/styles/helpers/breakpoints";
@import "../../../node_modules/@storefront-ui/shared/styles/helpers/typography";
@import "../../../node_modules/@storefront-ui/shared/styles/helpers/layout";
@import "../../../node_modules/@storefront-ui/shared/styles/helpers/colors";
@import "../../../node_modules/@storefront-ui/shared/styles/helpers/sizes";































































































































































.header-navigation {
  width: 100%;
  padding: 0 1%;
}
.sf-header-navigation-item {
  &__item {
    display: flex;
    gap: 20px;
    justify-content: space-between;
  }
}
