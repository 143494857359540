@import "../node_modules/@storefront-ui/shared/styles/helpers/breakpoints";
@import "../node_modules/@storefront-ui/shared/styles/helpers/typography";
@import "../node_modules/@storefront-ui/shared/styles/helpers/layout";
@import "../node_modules/@storefront-ui/shared/styles/helpers/colors";
@import "../node_modules/@storefront-ui/shared/styles/helpers/sizes";





















































.sf-header__logo {
  @include for-desktop {
    align-items: center;
    display: inline-flex;
    min-height: 80px;
  }
}
