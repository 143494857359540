@import "../node_modules/@storefront-ui/shared/styles/helpers/breakpoints";
@import "../node_modules/@storefront-ui/shared/styles/helpers/typography";
@import "../node_modules/@storefront-ui/shared/styles/helpers/layout";
@import "../node_modules/@storefront-ui/shared/styles/helpers/colors";
@import "../node_modules/@storefront-ui/shared/styles/helpers/sizes";




































.error-page {
  margin-top: var(--spacer-base);
  position: relative;
  text-align: center;

  @include for-desktop {
    margin-top: var(--spacer-2xl);
  }
}

.sf-link {
  display: inline-block;
  margin-top: var(--spacer-base);
  width: auto;

  &:hover,
  &:focus {
    color: var(--c-light-variant);
  }
}
