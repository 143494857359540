@import "../node_modules/@storefront-ui/shared/styles/helpers/breakpoints";
@import "../node_modules/@storefront-ui/shared/styles/helpers/typography";
@import "../node_modules/@storefront-ui/shared/styles/helpers/layout";
@import "../node_modules/@storefront-ui/shared/styles/helpers/colors";
@import "../node_modules/@storefront-ui/shared/styles/helpers/sizes";






















































































































































.navigation-bottom {
  --bottom-navigation-z-index: 3;

  &__add-to-cart {
    margin-left: -2px
  }

  ::v-deep {
    .sf-bottom-navigation-item {
      align-self: end;
    }

    .svg-image {
      margin-bottom: var(--spacer-xs);
    }
  }
}
