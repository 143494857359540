@import "../../../node_modules/@storefront-ui/shared/styles/helpers/breakpoints";
@import "../../../node_modules/@storefront-ui/shared/styles/helpers/typography";
@import "../../../node_modules/@storefront-ui/shared/styles/helpers/layout";
@import "../../../node_modules/@storefront-ui/shared/styles/helpers/colors";
@import "../../../node_modules/@storefront-ui/shared/styles/helpers/sizes";






























.header-navigation__subcategories {
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1000;
  ::v-deep .dropdown-menu {
    position: static;
    display: flex;
    float: none;
    width: 100%;
    border-top: none;
    margin: 0px;
    margin-top: -2px;
    h4 {
      font-size: 18px;
    }
    > li {
      padding-left: calc(3% + 15px);
      padding-bottom: 10px;
      min-height: 220px;
      margin: 20px 0px;
      padding-right: 35px;
      + li {
        border-left: 1px solid #cccccc;
      }
      ul {
        li {
          margin: 0px !important;
          list-style: none;
          padding: 5px 0 5px 0px !important;
          a {
            color: #777777;
            font-size: 14px;
            &:hover {
              color: #000000;
            }
          }
        }
      }
    }
    ul {
      list-style: none;
      padding-left: 0;
    }
  }
}
