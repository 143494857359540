$color_1: #000;
$color_2: #959595;
$color_3: #333;
$color_4: #fff;
$color_5: #337ab7;
$color_6: #23527c;
$color_7: inherit;
$color_8: #777;
$color_9: #286090;
$color_10: #3c763d;
$color_11: #2b542c;
$color_12: #31708f;
$color_13: #245269;
$color_14: #8a6d3b;
$color_15: #66512c;
$color_16: #a94442;
$color_17: #843534;
$color_18: #5cb85c;
$color_19: #5bc0de;
$color_20: #f0ad4e;
$color_21: #d9534f;
$color_22: #262626;
$color_23: #555;
$color_24: #5e5e5e;
$color_25: #ccc;
$color_26: #9d9d9d;
$color_27: #444;
$color_28: transparent;
$color_29: #6f4400;
$color_30: #e02b27;
$color_31: #1979c3;
$color_32: #006bb4;
$color_33: #b30000;
$color_34: #c07600;
$color_35: #006400;
$color_36: #323232;
$color_37: #888;
$color_38: #269e6d;
$color_39: #7f7f7f;
$color_40: #35312e;
$color_41: #43423d;
$color_42: #222;
$color_43: #999;
$color_44: #737373;
$color_45: #c7ddef;
$color_46: #f5f5f5;
$color_47: #dff0d8;
$color_48: #d9edf7;
$color_49: #fcf8e3;
$color_50: #f2dede;
$color_51: #c7c7c7;
$color_52: #ff5501;
$color_53: #989898;
$color_54: #4f4f4f;
$color_55: #111;
$color_56: #632121;
$color_57: rgba(204, 0, 0, .51);
$color_58: #ff2f00;
$color_59: #383838;
$color_60: #7e7c7a;
$color_61: #f3f2f0;
$color_62: red 0;
$color_63: #e4e4e4;
$color_64: #8f8f8f;
$color_65: #f9f6f1;
$color_66: #1a1a1a;
$color_67: #676767;
$color_68: #343434;
$color_69: #00f;
$color_70: #00008b;
$color_71: red;
$color_72: #adadad;
$color_73: green;
$color_74: #3c3c3c;
$color_75: #575757;
$color_76: #909090;
$color_77: #7b7b7b;
$color_78: #7f8394;
$color_79: #a2a2a2;
$color_80: #ff3b26;
$color_81: #949493;
$background-color_1: #fff;
$background-color_2: #fcf8e3;
$background-color_3: #337ab7;
$background-color_4: #286090;
$background-color_5: #dff0d8;
$background-color_6: #c1e2b3;
$background-color_7: #d9edf7;
$background-color_8: #afd9ee;
$background-color_9: #f7ecb5;
$background-color_10: #f2dede;
$background-color_11: #e4b9b9;
$background-color_12: #e6e6e6;
$background-color_13: #d4d4d4;
$background-color_14: #333;
$background-color_15: #204d74;
$background-color_16: #5cb85c;
$background-color_17: #449d44;
$background-color_18: #398439;
$background-color_19: #5bc0de;
$background-color_20: #31b0d5;
$background-color_21: #269abc;
$background-color_22: #f0ad4e;
$background-color_23: #ec971f;
$background-color_24: #d58512;
$background-color_25: #d9534f;
$background-color_26: #c9302c;
$background-color_27: #ac2925;
$background-color_28: transparent;
$background-color_29: #e5e5e5;
$background-color_30: #f5f5f5;
$background-color_31: #eee;
$background-color_32: #f8f8f8;
$background-color_33: #e7e7e7;
$background-color_34: #ddd;
$background-color_35: #888;
$background-color_36: #f9f6f1;
$background-color_37: #eadfcd;
$background-color_38: red 0;
$background-color_39: #656565;
$background-color_40: #323232;
$background-color_41: #f8f5f0;
$background-color_42: #f9f9f9;
$background-color_43: #e8e8e8;
$background-color_44: #d0e9c6;
$background-color_45: #c4e3f3;
$background-color_46: #faf2cc;
$background-color_47: #ebcccc;
$background-color_48: #777;
$background-color_49: #5e5e5e;
$background-color_50: #3c763d;
$background-color_51: #31708f;
$background-color_52: #8a6d3b;
$background-color_53: #a94442;
$background-color_54: #000;
$background-color_55: #f7f7f7;
$background-color_56: rgba(0, 0, 0, 0);
$background-color_57: #000 \9;
$background-color_58: rgba(82, 127, 50, .9);
$background-color_59: #527f32;
$background-color_60: #f6f6f6;
$background-color_61: rgba(51, 51, 51, .8);
$background-color_62: rgba(255, 255, 255, .3);
$background-color_63: none;
$background-color_64: rgba(0, 0, 0, .3);
$background-color_65: #f3f2f0;
$background-color_66: #e5f4bd;
$background-color_67: #ebebeb;
$background-color_68: #d7d7d7;
$background-color_69: rgba(51, 51, 51, .55);
$background-color_70: inherit;
$background-color_71: rgba(167, 167, 167, .4);
$background-color_72: #343434;
$border-color_1: transparent #ff8400 transparent transparent;
$border-color_2: transparent #ff9635 transparent transparent;
$border-color_3: transparent transparent transparent #ff9635;
$border-color_4: #ccc;
$border-color_5: #8c8c8c;
$border-color_6: #adadad;
$border-color_7: #2e6da4;
$border-color_8: #122b40;
$border-color_9: #204d74;
$border-color_10: #4cae4c;
$border-color_11: #255625;
$border-color_12: #398439;
$border-color_13: #46b8da;
$border-color_14: #1b6d85;
$border-color_15: #269abc;
$border-color_16: #eea236;
$border-color_17: #985f0d;
$border-color_18: #d58512;
$border-color_19: #d43f3a;
$border-color_20: #761c19;
$border-color_21: #ac2925;
$border-color_22: transparent;
$border-color_23: #337ab7;
$border-color_24: #eee #eee #ddd;
$border-color_25: #e7e7e7;
$border-color_26: #ddd;
$border-color_27: #eadfcd;
$border-color_28: #333;
$border-color_29: #eee6d8;
$border-color_30: #ed8380;
$border-color_31: #323232;
$border-color_32: #66afe9;
$border-color_33: #3c763d;
$border-color_34: #2b542c;
$border-color_35: #8a6d3b;
$border-color_36: #66512c;
$border-color_37: #a94442;
$border-color_38: #843534;
$border-color_39: #d6e9c6;
$border-color_40: #bce8f1;
$border-color_41: #faebcc;
$border-color_42: #ebccd1;
$border-color_43: #31708f;
$border-color_44: #cecece;
$border-color_45: #bebebe;
$border-color_46: #f8f5f0;
$border-color_47: #555 #fff;
$border-color_48: transparent transparent transparent #fff;
$border-color_49: transparent transparent transparent #333;
$border-color_50: red;
$border-color_51: #343434;
$border-color_52: #00f;
$border-color_53: #00008b;
$border-top-color_1: #000;
$border-top-color_2: #c9e2b3;
$border-top-color_3: #a6e1ec;
$border-top-color_4: #f7e1b5;
$border-top-color_5: #e4b9c0;
$border-top-color_6: #ddd;
$border-top-color_7: #337ab7;
$border-top-color_8: #d6e9c6;
$border-top-color_9: #bce8f1;
$border-top-color_10: #faebcc;
$border-top-color_11: #ebccd1;
$border-top-color_12: #999;
$border-top-color_13: rgba(0, 0, 0, .25);
$border-top-color_14: #fff;
$border-right-color_1: #000;
$border-right-color_2: #999;
$border-right-color_3: rgba(0, 0, 0, .25);
$border-right-color_4: #fff;
$border-bottom-color_1: transparent;
$border-bottom-color_2: #fff;
$border-bottom-color_3: #ddd;
$border-bottom-color_4: #337ab7;
$border-bottom-color_5: #d6e9c6;
$border-bottom-color_6: #bce8f1;
$border-bottom-color_7: #faebcc;
$border-bottom-color_8: #ebccd1;
$border-bottom-color_9: #000;
$border-bottom-color_10: #999;
$border-bottom-color_11: rgba(0, 0, 0, .25);
$border-left-color_1: #000;
$border-left-color_2: #999;
$border-left-color_3: rgba(0, 0, 0, .25);
$border-left-color_4: #fff;

.clearfix:before,
.clearfix:after,
.dl-horizontal dd:before,
.dl-horizontal dd:after,
.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after,
.nav:before,
.nav:after,
.navbar:before,
.navbar:after,
.navbar-header:before,
.navbar-header:after,
.navbar-collapse:before,
.navbar-collapse:after {
	content: " ";
	display: table;
}

.clearfix:after,
.dl-horizontal dd:after,
.container:after,
.container-fluid:after,
.row:after,
.nav:after,
.navbar:after,
.navbar-header:after,
.navbar-collapse:after {
	clear: both;
}

.full-row {
	margin: 10px 0 !important;
}

.FP-2up-1 {
	padding: 0 7.5px 0 0;
	margin-bottom: 2rem;
}

.FP-2up-2 {
	padding: 0 0 0 7.5px;
	margin-bottom: 2rem;
}

.FP-3up-1 {
	padding: 15px 10px 0 0;
	margin-bottom: 2rem;
}

.FP-3up-2 {
	padding: 15px 5px 0;
	margin-bottom: 2rem;
}

.FP-3up-3 {
	padding: 15px 0 0 10px;
	margin-bottom: 2rem;
}

.FP-TitleBox {
	width: 100%;
	text-align: center;
	bottom: 30px;
	position: block;
	padding: 0 0 6px;
	height: 120px;
	padding-top: 10px;

	h3 {
		font-size: 18px;
		line-height: 18px;
		font-weight: 400;
		margin-top: 20px;
		margin-bottom: 10px;
	}

	a {
		border: 1px solid #000;
		padding: 10px 60px;
		border-radius: 20px;

		&:hover {
			background-color: $background-color_54;
			color: $color_4;
		}
	}

	p {
		font-size: 14px;
		color: $color_3;
		margin-bottom: 25px;
		font-weight: 400;
	}
}

.full-row.free-ship {
	background: #f3f2f0;
	padding-top: 20px;
	padding-bottom: 20px;
	margin-top: 30px;
	text-align: center;

	p {
		margin: 0;
	}
}

.home-about-us.full-row {
	margin: 50px 0 30px;

	h3 {
		font-weight: 700;
		font-size: 19px;
		margin-bottom: 0;
		padding: 0 0 30px;
		margin: 0;
	}

	p {
		margin-bottom: 20px;
	}
}

img {
	vertical-align: middle;
	border: 0;
}

.img-responsive {
	display: block;
	max-width: 100%;
	height: auto;
}

.img-rounded {
	border-radius: 6px;
}

.img-thumbnail {
	padding: 4px;
	line-height: 1.42857143;
	background-color: $background-color_1;
	border: 1px solid #ddd;
	border-radius: 4px;
	-webkit-transition: all .2s ease-in-out;
	-o-transition: all .2s ease-in-out;
	transition: all .2s ease-in-out;
	display: inline-block;
	max-width: 100%;
	height: auto;
}

// .img-circle {
// 	border-radius: 50%;
// }

.mixcat_showcaseBlock {
	width: 100%;
	padding: 0 3%;
}

.mixcat_showcaseBlock_ImageLeft {
	width: 50%;
	padding-right: 0.5%;
	float: left;

	img {
		width: 100%;
	}
}

.mixcat_showcaseBlock_ImageRight {
	width: 50%;
	padding-left: 0.5%;
	float: left;

	img {
		width: 100%;
	}
}

.mixcat_showcaseBlock_TextBelow {
	width: 100%;
	padding: 10px 30px;
	text-align: center;
	display: inline-block;

	h2 {
		margin: 0 auto;
		max-width: 550px;
		font-weight: 600;
		font-size: 20px;
		font-family: Lato, sans-serif;
		padding: 10px;
	}

	p {
		margin: 0 auto;
		max-width: 550px;
		font-weight: 400;
		font-size: 15px;
		font-family: Lato, sans-serif;
	}
}

.mixcat_showcase3xBlock {
	width: 100%;
	padding: 0 3% 50px;
	display: inline-block;
}

.FP-TitleBox {
	margin-bottom: 20px;
}

.mixcat_CarrierBallBlock,
.mixcat_ColourBallBlock {
	width: 100%;
	margin-top: 10px;
	padding: 0 3%;
	display: inline-block;
	margin-bottom: 20px;
}

.mixcat_CarrierBallBlock_Title,
.mixcat_ColourBallBlock_Title {
	width: 100%;
}

.mixcat_CarrierBallBlock_Title h2,
.mixcat_ColourBallBlock_Title h2 {
	font-size: 18px;
	font-weight: 400;
	font-family: Lato, sans-serif;
}

.mixcat_CarrierBallBlock_Ball {
	width: 24%;
	display: inline-block;
	margin-top: 10px;
}

.mixcat_CarrierBallBlock_BallImage {
	width: 100%;
	float: left;
}

.mixcat_CarrierBallBlock_BallImage img {
	width: 100%;
}

.mixcat_CarrierBallBlock_BallText {
	width: 90px;
	padding-top: 30px;
	float: left;
}

.mixcat_CarrierBallBlock_BallText p {
	font-size: 12px;
	font-weight: 400;
	padding-left: 6px;
}

.mixcat_CarrierBallBlock_BallImage h1 {
	color: white;
	font-size: 1.2em;
}

.mixcat_CarrierBallBlock_BallImage {
	background-color: #c00000;
	text-align: center;
}

.mixcat_ColourBallBlock_Ball {
	width: 8%;
	margin-top: 10px;
	padding-right: 5px;
	float: left;
	min-width: 85px;
}

.mixcat_ColourBallBlock_BallImage img {
	width: 100%;
}

.mixcat_ColourBallBlock_BallText {
	width: 100%;
	padding-top: 10px;
	display: inline-block;
	text-align: center;
	height: 50px;
}

.mixcat_ColourBallBlock_BallText p {
	font-size: 12px;
	font-weight: 400;
	padding-left: 6px;
}

.FP-TitleBox {
	margin-bottom: 20px;
}

@media only screen and (max-width: 1024px) {}

@media (max-width: 768px) {
	.mixcat_showcase3xBlock_Image {
		width: 50%;
	}

	.mixcat_CarrierBallBlock_Ball {
		width: 49%;
	}
}

@media (max-width: 640px) {
	.mixcat_showcaseBlock_ImageLeft {
		width: 100%;
		clear: both;
		padding: 0;
	}

	.mixcat_showcaseBlock_ImageRight {
		width: 100%;
		clear: both;
		padding: 5px 0 0;
	}

	.mixcat_showcase3xBlock_Image {
		width: 100%;
	}

	.mixcat_CarrierBallBlock_Ball {
		width: 99%;
	}
}

.carousel-cell {
	counter-increment: carousel-cell;
	margin-right: 10px;
}

.carousel-cell-fullWidth {
	counter-increment: carousel-cell;
	margin-right: 0px;
}

.breadcrumb {
	display: none;
}

.catalog-category-view {
	.page-title-wrapper {
		padding-bottom: 0px !important;
	}
}

.showcaseBreadcrumbLine {
	width: 100%;
	padding: 0 3%;
	display: inline-block;
	margin: 3px 0 10px;

	span {
		font-size: 13px;
		font-weight: 400;
		font-family: Lato, sans-serif;
		text-decoration: underline;
		color: #999;
	}
}

.showcaseSlider {
	width: 100%;
	margin: 10px 0 10px;
	padding: 0 3%;
	display: inline-block;
}

.showcaseSliderFullwidthBlockImage {
	img {
		width: 100%;
		height: 100%;
	}
}

.showcaseSliderBlock {
	width: 100% !important;
	max-width: 400px;
}

.showcaseSliderBlockImage {
	margin-right: 10px;
	float: left;

	img {
		width: 100%;
		border-radius: 20px;
	}
}

.main-carousel {
	.flickity-viewport {
		min-height: 600px;
	}
}

.showcaseBlock {
	width: 100%;
	padding: 0 3%;
	margin-top: 10px;
}

.showcaseBlockItem {
	width: 33%;
	float: left;
}

.showcaseBlockItem2up {
	width: 50%;
	float: left;
}

.showcaseTextBlock {
	width: 100%;
	margin-top: 10px;
	padding: 0 3%;
	display: inline-block;

	h1 {
		font-size: 24px;
		font-weight: 400;
		font-family: Lato, sans-serif;
		margin-bottom: 5px;
	}

	p {
		font-size: 14px;
		font-weight: 400;
		font-family: Lato, sans-serif;
		color: #666666;
	}

	h2 {
		font-size: 20px;
		font-weight: 400;
		font-family: Lato, sans-serif;
		margin: 0;
	}
}

.showcaseTopTextBlock {
	width: 100%;
	margin: 10px 0 0;
	padding: 0 3%;
	display: inline-block;

	h1 {
		font-size: 30px;
		font-weight: 400;
		font-family: Lato, sans-serif;
		margin: 6px 0 10px;
	}

	h2 {
		font-size: 22px;
		font-weight: 400;
		font-family: Lato, sans-serif;
		margin: 0;
	}
}

.showcaseSliderFullWidth {
	width: 100%;
	margin-top: 10px;
	display: inline-block;
	min-height: 500px;

	.flickity-viewport {
		min-height: 500px;
	}
}

.showcaseSlider_Title {
	width: 100%;

	h2 {
		font-size: 16px;
		font-weight: 400;
		font-family: Lato, sans-serif;
		margin-bottom: 1px;
	}
}

.showcaseSliderMenu {
	width: 120px;
	margin-top: 10px;
	float: left;
	background-color: #faf9f7;
	border-radius: 15px;
	height: 165px;
}

.showcaseSliderMenuImage {
	pointer-events: none;
	margin-top: 0px;

	img {
		width: 100%;
		border-radius: 10px;
	}
}

.showcaseSliderMenuText {
	width: 100%;
	display: inline-block;
	text-align: center;
	height: 50px;
	padding-top: 3px;

	p {
		font-size: 13px;
		font-weight: 400;
		margin: 0;
	}
}

.showcaseSliderMenuTextFirst {
	padding-top: 60px;
}

.FP-TitleBox {
	margin-bottom: 20px;
	height: 100%;
}

.flickity-button {
	display: none;
}

.showcaseBottomTitleBlock {
	background-color: #fbfaf8;
	width: 100%;
	margin-top: 10px;
	padding: 10px 3% 3px;
	display: inline-block;

	h1 {
		font-size: 24px;
		font-weight: 400;
		font-family: Lato, sans-serif;
		margin-bottom: 0;
	}

	span {
		font-size: 13px;
		font-weight: 400;
		font-family: Lato, sans-serif;
		text-decoration: underline;
		padding-left: 5px;
	}
}

.showcaseSliderBlockText {
	width: 80%;
	text-align: center;
	margin: 0 auto 30px;

	h2 {
		font-size: 24px;
		margin: 10px 0 3px;
		font-family: Lato, sans-serif;
	}

	h3 {
		font-size: 18px;
		margin: 10px 0 10px;
		font-family: Lato, sans-serif;
	}

	p {
		font-size: 15px;
		font-weight: 400;
		color: #696969;
	}
}

.showcaseSliderBlockButton {
	text-align: center;
	background-color: black;
	color: white;
	width: 110px;
	display: inline-block;
	line-height: 31px;
	border-radius: 25px;
	font-weight: 400;
	font-size: 13px;
}

@media only screen and (max-width: 1024px) {
	.showcaseSliderBlockText {
		h2 {
			font-size: 20px;
		}

		p {
			font-size: 14px;
		}
	}
}

@media (max-width:768px) {
	.showcaseSliderBlockText {
		h2 {
			font-size: 18px;
		}

		p {
			font-size: 13px;
		}
	}
}

@media (max-width:640px) {
	.showcaseSliderFullWidth {
		min-height: 300px;

		.flickity-viewport {
			min-height: 300px;
		}
	}

	.showcaseBlockItem {
		width: 100%;
	}

	.showcaseBlockItem2up {
		width: 100%;
	}

	.main-carousel {
		.flickity-viewport {
			min-height: 400px;
		}
	}
}

/*------------------ABOUT US-------------------------------*/
.BlockContent {
	padding: 0px 3%;
}

#About-Top {
	width: 75%;
	margin: 50px auto 0;
}

#About-Top h2 {
	text-align: center;
}

#About-MissionStatement h2 {
	font-family: "Lato", sans-serif !important;
	text-align: center;
	font-size: 25px;
	margin-bottom: 20px;
	line-height: 29px;
	font-weight: normal;
}

#About-MissionStatement p {
	font-family: "Lato", sans-serif !important;
	text-align: center;
	font-size: 17px;
	line-height: 22px;
	color: #878787;
}

#About-MissionStatement hr {
	margin-top: 60px;
}

#About-MYJSLogo {
	width: 175px;
	margin: 0 auto 30px;
	clear: both;
}

#About-MissionStatement {
	width: 80%;
	margin: 0 auto;
}

#About-Body {
	width: 80%;
	margin: 0 auto 0;
	border-bottom: 1px solid #dfdfdf;
}

.About-Body-Chunks h3,
#About-Career .About-Body-Chunks h3,
#About-CustomerTestimonials h3 {
	font-family: "Lato", sans-serif !important;
	text-align: center;
	font-size: 25px;
	margin-bottom: 20px;
	font-weight: normal;
	line-height: 29px;
}

#About-Body .About-Body-Chunks p,
#About-Career .About-Body-Chunks p {
	font-family: "Lato", sans-serif !important;
	text-align: center;
	font-size: 17px;
	line-height: 22px;
	color: #878787;
}

#About-Body .About-Body-Chunks {
	margin: 100px auto 0;
	width: 90%;
}

#About-Career .About-Body-Chunks {
	margin: 50px auto 0;
	width: 90%;
}

.About-LinkButton {
	margin: 50px auto 60px;
	width: 292px;
	display: block;
}

.About-LinkButton-reviews {
	margin: 50px auto 50px;
	width: 292px;
	display: block;
	clear: both;
	padding-top: 50px;
}

#About-Guarantee {
	margin: 0 auto 30px;
	width: 80%;
	text-align: center;
	display: block;
}

.About-TestimonialBoxes-thumb {
	background: url(/pub/media/wysiwyg/ReviewHeadCircle.jpg) no-repeat;
	background-size: 60px 55px;
	height: 55px;
	width: 60px;
	float: left;
	margin-right: 15px;
}

.About-TestimonialBoxes-thumb h3 {
	padding-top: 14px;
	color: white;
	font-weight: bold;
	font-size: 26px !important;
	padding-right: 2px;
}

.About-TestimonialBoxes-thumb h3 {
	margin-top: 0px;
}

.About-TestimonialBoxes {
	width: 100%;
	padding: 50px 0 10px;
	float: left;
}

.About-TestimonialBoxes-text {
	float: left;
	display: block;
	width: 75%;
}

.About-TestimonialBoxes-text h3 {
	text-align: left !important;
	font-size: 17px !important;
	margin-bottom: 5px !important;
	font-weight: bold !important;
}

.About-TestimonialBoxes-text p {
	font-family: "Lato", sans-serif !important;
	text-align: left;
	font-size: 17px;
	color: #878787;
	margin-bottom: 5px;
	line-height: 22px;
}

.About-TestimonialBoxes-text h4 {
	font-family: "Lato", sans-serif !important;
	text-align: left;
	font-size: 15px;
	margin-bottom: 5px;
	margin-top: 5px;
}

.About-TestimonialBoxes-text h4 em img {
	margin-bottom: -4px;
}

#About-TestimonialBigBox {
	width: 85%;
	margin: 0 auto;
	overflow: hidden;
}

#About-TestimonialBigBoxRight,
#About-TestimonialBigBoxLeft {
	width: 50%;
	float: left;
	padding-left: 15px;
	padding-right: 15px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

#stamped-reviews-widget {
	padding-top: 40px;
}

/*------------------Packaging & Gift Wrapping -------------------------------*/

.freeship_head {
	font-size: 28px;
	padding: 50px 0;
	color: #000;
	line-height: 43px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	font-weight: 300;
}

.shiptopcotentblock {
	border-bottom: 1px solid #e5e5e5;
	padding-bottom: 80px;
	font-size: 16px;
	max-width: 807px;
	color: #6b6b6b;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	padding-right: 160px;
}

.shiptopcotentblock p {
	margin-bottom: 15px;
	line-height: 22px;
	font-size: 17px;
}

.shipcontentblock {
	padding: 70px 180px 65px 0;
	border-bottom: 1px solid #e5e5e5;
	max-width: 807px;
	color: #6b6b6b;
	font-size: 16px;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
}

.shipcontentblock p {
	margin-bottom: 15px;
	line-height: 22px;
	font-size: 17px;
}

.shipcontentblock h2 {
	font-size: 28px;
	color: #000;
	padding: 0 0 25px;
	margin: 0;
}

.shoplistblock {
	overflow: hidden;
	padding-bottom: 22px;
}

.shoplistblock ul {
	float: left;
	margin: 0;
	padding: 0;
}

.shoplistblock .shoplist1 {
	width: 120px;
}

.shoplistblock .shoplist2 {
	width: 154px;
}

.shoplistblock .shoplist3 {
	width: 150px;
}

.page-title-wrapper {
	padding: 0 !important;
}

.shoplistblock ul li {
	list-style-type: none;
}

.pers_headblock {
	background-colour: white;
}

.pers_headblock_bannertext {
	text-align: center;
	max-width: 730px;
	margin: 20px auto 30px;
	padding: 0 20px;
}

.pers_headblock_bannertext p {
	font-size: 14px;
	font-weight: 400 !important;
}

.pers_headblock_bannermb {}

.pers_headblock_bannermb img {
	width: 100%;
}

.pers_headblock_bannerdt {
	padding: 0 4%;
}

.pers_headblock_bannerdt img {
	max-width: 1440px;
	width: 100%;
}

.pers_headblock_bannerheader {
	text-align: center;
	width: 100%;
}

.pers_headblock_bannerheader h2 {
	font-size: 25px;
	color: black;
	margin-top: 20px;
	font-weight: 400;
	font-family: Lato, sans-serif;
}

@media all and (min-width: 768px) {
	.pers_headblock_bannermb {
		display: none;
	}
}

@media only screen and (max-width: 1024px) {
	.pers_headblock_bannermb {
		display: none;
	}

}

@media (max-width:640px) {
	.pers_headblock_bannerdt {
		display: none;
	}

	.pers_headblock_bannermb {
		display: block;
	}
}