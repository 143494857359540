@import "../../../node_modules/@storefront-ui/shared/styles/helpers/breakpoints";
@import "../../../node_modules/@storefront-ui/shared/styles/helpers/typography";
@import "../../../node_modules/@storefront-ui/shared/styles/helpers/layout";
@import "../../../node_modules/@storefront-ui/shared/styles/helpers/colors";
@import "../../../node_modules/@storefront-ui/shared/styles/helpers/sizes";








































.sf-header-navigation-item__link {  
  display: block;
  flex: none !important;
}
.sf-header-navigation-item__link_sale {
  display: block;
  text-align: right !important;
  margin-right: 30px !important;
}
.sf-header-navigation-item__item--desktop > *:not(.sf-mega-menu) {
  padding: 1% 0px !important;
}
