@import "../../../node_modules/@storefront-ui/shared/styles/helpers/breakpoints";
@import "../../../node_modules/@storefront-ui/shared/styles/helpers/typography";
@import "../../../node_modules/@storefront-ui/shared/styles/helpers/layout";
@import "../../../node_modules/@storefront-ui/shared/styles/helpers/colors";
@import "../../../node_modules/@storefront-ui/shared/styles/helpers/sizes";






























































































































.header-navigation {
  @include for-mobile {
    display: none;
  }
  &__main {
    display: flex;
  }
}
.nav-item {
  margin-left: 20px;
  --header-navigation-item-margin: 0 var(--spacer-sm);
  .sf-header-navigation-item__item--mobile {
    display: none;
  }
}
 
